import React from "react"

import { PageLayout } from "@components/layouts"
import SEO from "@components/seo"
import TextBlock from "@components/textBlock"
import SingleColumnBlock from "@components/singleColumnBlock"
import PageWrapper from "@components/pageWrapper"
import { VideoPlayer } from "@components/video"

import {
  ScrollToButton, LearnMoreButton,
} from "@components/buttons"

import {
  FormWrapper,
  RequestMoreInfo,
} from "@components/Forms"

const IndexPage = () => {
  return (
    <PageLayout>
      <SEO
        title="Striking Sync | Offbeat Music for Film and Television"
        ogDescription="Striking Sync provides unusual sync licencing selections for film and television music supervisors by invite only."
        url="https://strikingsync.com"
      />
      <PageWrapper color="blue">
        <TextBlock
          textPadded
          textLeft={
            <div>
                <h1>Striking Sync</h1>
                <h2>Offbeat Music for Film and Television</h2>
                <p>
                  Striking Sync was created by producer/artist <a href="https://jimmyether.com" target="_blank">Jimmy Ether</a> and the <a href="https://headphonetreats.com" target="_blank">Headphone Treats label</a> to provide beats and music beds to film and telvision productions that are unlike what's available in typical sync libraries.  We know quality quirk, and make that available to you.
                </p>
                <p>
                  The platform will be launching in early 2023 and is by invite only.
                </p>
            </div>
          }
          textRight={
            <div>
              <img src="/images/logo.png" alt="Striking Sync" />
            </div>
          }
        />
      </PageWrapper>
      <SingleColumnBlock
        textPadded
        text={
          <div>
            <h2>If you are a music supervisor who would like an invite, please fill out the form.</h2>
            <RequestMoreInfo />
          </div>
        }
      />
    </PageLayout>
  )
}

export default IndexPage
